.preloader {
    height: 100vh;
    width: 100%;
    background: var(--navy);
    background-image: url(../../assets/bg-texture.png);
    color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader__container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: max-content max-content;
    place-items: center;
    justify-content: center;
    align-items: center;
}

.preloader__img {
    width: 120px;
    aspect-ratio: 1/1;
    padding-bottom: 4rem;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
}

.preloader__text {
    font-family: 'Crimson Text', serif;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 72px;
    overflow: hidden;
    color: var(--white);
}

.preloader__text span {
    margin: 15px;
}

/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
    .preloader__text {
        font-size: 50px;
    }
}