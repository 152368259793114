#experience {
  text-align: center;
  padding-top: 3rem;
}

.experience__container {
  display: grid;
  transform: translateY(-20px);
  grid-template-rows: auto 1fr;
  gap: 1rem;
  justify-items: center;
}

.experience__cards {
  width: 85%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 20px 20px;
  transition: all 0.4s ease-in-out;
}

.experience__card {
  width: 80%;
  padding: 1rem;
}

.experience__card-active {
  width: 80%;
  padding: 1rem;
  cursor: default;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
  border-radius: 2rem;
  transform: translateY(-8px);
}

.experience__card-logo {
  width: 60%;
  aspect-ratio: 1;
  border-radius: 2rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 1rem auto;
}

.experience__card-logo:hover {
  transform: translateY(-8px);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.9);
}

.experience__card-title {
  margin: 1rem;
  color: var(--color-primary);
  font-size: 1.2rem;
}

.experience__card-active .experience__card-title {
  color: blanchedalmond;
}

.experience__details {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 95%;
  height: auto;
  padding: 0 10px;
}

.experience__details h3 {
  font-family: 'Crimson Text', serif;
  font-weight: 500;
  color: blanchedalmond;
  font-size: 1.8rem;
}

.experience__details-company {
  font-size: 1.2rem;
  color: var(--color-primary);
}

.experience__details-separator {
  font-size: 1.4rem;
  font-weight: 500;
  color: blanchedalmond;
}

.experience__details-period {
  font-size: 1.2rem;
  color: var(--color-primary);
}

.experience__details-description {
  padding-top: 1rem;
  text-align: justify;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES like Tablets) ============== */
@media screen and (max-width: 1024px) {
  #experience {
    height: auto;
  }
  
  .experience__container {
    grid-template-rows: 1.6fr 1fr;
  }
  
  .experience__cards {
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .experience__details-section {
    width: 100%;
  }
}


/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
  .experience__container {
    grid-template-rows: 1fr max-content;
  }

  .experience__cards {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .experience__details-section {
    width: 100%;
  }

  .experience__details-description {
    font-size: 0.9rem;
  }
}