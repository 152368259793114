nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.6rem 1.7rem;
    margin: 0rem auto;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 2rem;
    backdrop-filter: blur(15px);
}

nav a {
    position: relative;
    width: 70px;
    height: 70px;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
}

nav a .icon {
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5rem;
    text-align: center;
    transition: 0.5s;
}

nav a:hover .icon {
    transform: translateY(-20px);
}

/* nav a.active .icon {
    transform: translateY(-35px);
} */

nav a .text {
    position: absolute;
    font-weight: 300;
    font-size: 0.75rem;
    letter-spacing: 0.05rem;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

nav a:hover .text {
    opacity: 1;
    transform: translateY(10px);
}

/* nav a.active .text {
    opacity: 1;
    transform: translateY(10px);
} */

/* nav a.active {
    color: var(--white);
} */

/* nav .indicator {
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    top: -35%;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid var(--navy);
    transition: 0.5s;
}


nav a:nth-child(1).active ~ .indicator {
    transform: translateX(calc(80px * 0));
}

nav a:nth-child(2).active ~ .indicator {
    transform: translateX(calc(80px * 1 + 2px));
}

nav a:nth-child(3).active ~ .indicator {
    transform: translateX(calc(80px * 2 + 5px));
}

nav a:nth-child(4).active ~ .indicator {
    transform: translateX(calc(80px * 3 + 8px));
}

nav a:nth-child(5).active ~ .indicator {
    transform: translateX(calc(80px * 4 + 12px));
} */

/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
    nav {
        bottom: 0rem;
    }
}