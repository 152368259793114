#about {
    padding-top: 3rem;
}

.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    transform: translateY(-1rem);
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, transparent, var(--slate), transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: all 0.4s ease-in-out;
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1.1rem 1rem;
    text-align: center;
    transition: all 0.5s ease;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.6);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
}

.about__card h5 {
    font-family: 'Crimson Text', serif;
    color: var(--color-primary);
    font-size: 1.3rem;
}

.about__card small {
    font-size: 0.8rem;
    color: var(--color-light);
}

.about__paragraph {
    margin: 1.5rem 0 1.5rem;
    color: var(--slate);
    text-align: justify;
    font-size: 0.95rem;
}

.about__paragraph span {
    color: var(--color-primary);

}

/*=============== Call to Action ================*/
.cta {
    display: flex;
    justify-content: center;
}

.cta a {
    font-weight: 500;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 1rem;
    padding: 18px 30px;
    font-size: 16px;
    margin-right: 20px;
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.cta a::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    border-radius: 1rem;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.cta a:hover {
    color: #121212;
    border-radius: 1rem;
    border: 1px solid #121212;
}

.cta a:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 1rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES like Tablets) ============== */
@media screen and (max-width: 1024px) {
    #about {
        height: auto;
    }
    
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 0rem auto 4rem;
    }

    .about__paragraph {
        margin: 1rem 0 1rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__paragraph {
        margin: 1.5rem 0;
        font-size: 0.9rem;
    }
}