@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,600;1,700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,600;1,700&family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2,12,27,0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100,255,218,0.1);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  /* font-family: 'Crimson Text', serif; */
  font-family: 'Montserrat', sans-serif;
  background: var(--navy);
  color: var(--slate);
  line-height: 1.7;
  background-image: url(../src/assets/bg-texture.png);
}

/* =============== GENERAL STYLE ================= */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 3rem;
  font-family: 'Crimson Text', serif;
}

h2 {
  font-family: 'Crimson Text', serif;
  font-size: 2.5rem;
}

section {
  margin-top: 7.5rem;
  height: 100vh;
}

section > h4, section > h5 {
  text-align: center;
  color: var(--color-primary);
}

section > h2 {
  text-align: center;
  color: var(--white);
  font-size: 3rem;
  margin-bottom: 2.5rem; 
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

li {
  list-style-type: '>  ';
  margin-bottom: 10px;
}

li::marker {
  color: var(--color-primary);
  font-weight: 700;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES like Tablets) ============== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-bottom: 2rem;
  }
}































