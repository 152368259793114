#contact {
    padding-top: 3rem;
}

#contact h2 {
    margin-bottom: 2rem;
}

.contact__container {
    width: 60%;
    display: grid;
    place-items: center;
}

.contact__text {
    width: 70%;
    text-align: center;
    padding-bottom: 2rem;
}

form {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.2rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--white);
    font-family: 'Montserrat', sans-serif;
    font-size: small;
}

button {
    width: max-content;
    font-weight: 500;
    color: var(--color-primary);
    border: 2px solid var(--color-primary-variant);
    border-radius: 1rem;
    padding: 18px 25px;
    font-size: 16px;
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    border-radius: 1rem;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

button:hover {
    color: #121212;
    border-radius: 1rem;
    border: 2px solid #121212;
}

button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 1rem;
}

#status_msg {
    color: var(--white);
    position: relative;
    padding: 0rem 3rem;

}

/* =============== MEDIA QUERIES (MEDIUM DEVICES like Tablets) ============== */
@media screen and (max-width: 1024px) {
    .contact__container {
        width: 80%;
    }

    
    .contact__text {
        width: 80%;
        }

    form {
        width: 70%;
        }
}

/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
    .contact__container {
        width: 100%;
    }

    
    .contact__text {
        width: 85%;
        }

    form {
        width: 85%;
        }
}