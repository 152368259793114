footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
    transition: all 0.3s ease-in-out;
}

.footer__logo {
    font-family: 'Crimson Text', serif;
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: inline-block;
}

.footer__links {
    font-family: 'Crimson Text', serif;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__links li {
    transition: all 0.4s ease-in-out;
}

.footer__links li:hover {
    transform: translateY(-4px);
}

.footer__CV {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.footer__CV a {
    background: var(--color-bg);
    color: var(--white);
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 2rem;
    border-radius: 1rem;
    display: flex;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

.footer__CV a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--white);
    font-size: 1rem;
    padding: 0.8rem;
    border-radius: 50%;
    display: flex;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    font-family: 'Crimson Text', serif;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 6rem;
    color: var(--color-bg);
}

/* =============== MEDIA QUERIES (SMALL DEVICES like Phones) ============== */
@media screen and (max-width: 600px) {
    .footer__links {
        flex-direction: column;
        gap: 1.2rem;
    }

    .footer__copyright {
        margin-bottom: 4rem;
    }
}